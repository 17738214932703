<template>
  <iframe src="https://www.chipsresale.com/admin/user.need/batch"></iframe>
</template>

<script>
export default {
  name: "user.need.batch",
  setup(){
    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>